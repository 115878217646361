<script>
import router from "@/router";
import { layoutComputed } from "@/state/helpers";

import Topbar from "@/components/topbar";
import SideBar from "@/components/side-bar";
import Footer from "@/components/footer";

export default {
  components: { Topbar, SideBar, Footer },
  data() {
    return {
      isMenuCondensed: null
    };
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.setAttribute("data-sidebar", "dark");
  },
  computed: {
    ...layoutComputed,
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
      localStorage.setItem("toggleMenu", this.isMenuCondensed)
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
  mounted() {
    var m = localStorage.getItem("toggleMenu")
    if (m == "true") {
      if (!document.body.classList.contains('sidebar-enable')) {
        document.body.classList.toggle("sidebar-enable");
        document.body.classList.toggle("vertical-collpsed");
        this.isMenuCondensed = true
      } else {
        this.isMenuCondensed = false
      }
      // eslint-disable-next-line no-unused-vars
    } else {
      if (m == null) {
        document.body.classList.toggle("sidebar-enable");
          document.body.classList.toggle("vertical-collpsed");
          this.isMenuCondensed = true
      } else {
        if (document.body.classList.contains('sidebar-enable')) {
          document.body.classList.toggle("sidebar-enable");
          document.body.classList.toggle("vertical-collpsed");
          this.isMenuCondensed = true
        } else {
          this.isMenuCondensed = false
        }
      }

    }


    // document.body.classList.remove("auth-body-bg");
    // if (this.loader === true) {
    //   document.getElementById("preloader").style.display = "block";
    //   document.getElementById("status").style.display = "block";

    //   setTimeout(function () {
    //     document.getElementById("preloader").style.display = "none";
    //     document.getElementById("status").style.display = "none";
    //   }, 2500);
    // } else {
    //   document.getElementById("preloader").style.display = "none";
    //   document.getElementById("status").style.display = "none";
    // }
  },
};
</script>

<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner">
          <i class="ri-loader-line spin-icon"></i>
        </div>
      </div>
    </div>

    <!-- Begin page -->
    <div id="layout-wrapper">
      <Topbar />
      <SideBar :is-condensed="isMenuCondensed" :type="leftSidebarType" :width="layoutWidth" />
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <router-view />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>