<script>
import CountryFlag from 'vue-country-flag'

export default {
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem("user") ?? "{}").first_name + " " + JSON.parse(localStorage.getItem("user") ?? "{}").last_name,
      languages: [
        {
          flag: "usa",
          language: 'gb',
          title: 'english',
        },
        {
          flag: "mk",
          language: 'mk',
          title: 'macedonian',
        },
        {
          flag: "al",
          language: 'al',
          title: 'albanian',
        },
        {
          flag: "srb",
          language: 'rs',
          title: 'serbian',
        },
        {
          flag: "bg",
          language: 'bg',
          title: 'bulgarian',
        },
        {
          flag: "de",
          language: 'de',
          title: 'deuchland',
        },
        {
          flag: "gr",
          language: 'gr',
          title: 'greek',
        }, {
          flag: "it",
          language: 'it',
          title: 'italian',
        }
      ],
      current_language: localStorage.getItem("locale") ?? "en",
      platforms: JSON.parse(localStorage.getItem("platforms") ?? "[]"),
      currentPlatform: JSON.parse(localStorage.getItem("platformId") ?? "")
    };
  },
  components: { CountryFlag },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale) {
      localStorage.setItem("locale", locale)
      location.reload();
    },
    setPlatform(platformId){
      localStorage.setItem("platformId", platformId)
      location.reload();
      this.currentPlatform = platformId
    },
    getCurrentLanguageImage(){
      for (let i = 0; i < this.languages.length; i++) {
        if (this.current_language === this.languages[i].language){
  
          return this.languages[i].flag
        }
      }
    },
    getCurrentPlatform() {
      for (let i = 0; i < this.platforms.length; i++) {
        if (this.currentPlatform === this.platforms[i].domain.id){
          return this.platforms[i].domain.title
        }
      }
    }
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm-dark.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="20" />
            </span>
          </a>

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm-light.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="20" />
            </span>
          </a>
        </div>

        <button @click="toggleMenu" type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn">
          <i class="ri-menu-2-line align-middle"></i>
        </button>

      </div>

      <div class="d-flex">
        <b-dropdown variant="white" right toggle-class="header-item" >
          <template v-slot:button-content>
            <country-flag :country="getCurrentLanguageImage()" size='normal' class="align-middle" style="margin:0px!important"/>


          </template>
          <b-dropdown-item class="notify-item" v-for="(entry, i) in languages" v-bind:key="`Lang${i}`" :value="entry"
            @click="setLanguage(entry.language)" :link-class="{ 'active': entry.language === current_language }">
            <country-flag :country="`${entry.flag}`" size='normal' class="mr-1 align-middle" style="margin:0px!important"/>
            <span class="align-middle">{{ $t('language.' + entry.title) }}</span>
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown variant="white" right toggle-class="header-item" >
          <template v-slot:button-content>
            {{ getCurrentPlatform() }}
          </template>
          <b-dropdown-item class="notify-item" v-for="(entry, i) in platforms" v-bind:key="`${i}`" :value="entry"
            @click="setPlatform(entry.domain.id)" :link-class="{ 'active': entry.domain.id === currentPlatform }">
            <span class="align-middle">{{  entry.domain.title}}</span>
          </b-dropdown-item>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button type="button" class="btn header-item noti-icon waves-effect" @click="initFullScreen">
            <i class="ri-fullscreen-line align-middle"></i>
          </button>
        </div>


        <b-dropdown right variant="black" toggle-class="header-item" class="d-inline-block user-dropdown">
          <template v-slot:button-content>
            <img class="rounded-circle header-profile-user" src="@/assets/images/users/avatar-2.jpg"
              alt="Header Avatar" />
            <span class="d-none d-xl-inline-block ml-1">{{ currentUser }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <a class="dropdown-item text-danger" href="/logout">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t('user.logout') }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}
</style>